import type { AxiosResponse } from 'axios';
import qs from 'qs';

import { Job } from 'store/job-queue/jobs.types';
import { ApiResponse } from 'types/api.types';
import { Contact } from 'types/contact.types';
import {
  ContactGroup,
  ContactGroupContactsParams,
  ContactGroupParams,
  ContactGroupSearchParams,
} from 'types/contact-group.types';
import { ContactTag } from 'types/contact-tag.types';
import { User } from 'types/user.types';

import { QueryMode } from 'components/@contact-groups/AddContactsStep/types';

import RestApiService from '.';

export function fetchContactGroups(
  this: RestApiService,
  userId: User['id'],
  params?: ContactGroupSearchParams,
) {
  return this.api.get<ApiResponse<ContactGroup[]>>(`/users/${userId}/contactgroups`, { params });
}

export function fetchContactGroup(this: RestApiService, groupId: ContactGroup['id']) {
  return this.api.get<ContactGroup>(`/contactgroups/${groupId}`);
}

export function fetchDeleteContactGroupAsyncTask(
  this: RestApiService,
  userId: User['id'],
  jobIds: Job['requestId'][] = [],
) {
  return this.api.get<ApiResponse<Job[]>>(`/users/${userId}/contactgroups/asyncTask`, {
    params: { requestIds: jobIds },
    paramsSerializer: p => qs.stringify(p, { arrayFormat: 'comma' }),
  });
}

export function fetchContactGroupContacts(
  this: RestApiService,
  groupId: ContactGroup['id'],
  params?: ContactGroupContactsParams,
) {
  return this.api.get<ApiResponse<Contact[]>>(`/contactgroups/${groupId}/contacts`, { params });
}

export function createContactGroup(this: RestApiService, params: ContactGroupParams) {
  return this.api.post<ContactGroupParams, AxiosResponse<ContactGroup>>(`/contactgroups`, params);
}

export function updateContactGroup(
  this: RestApiService,
  groupId: ContactGroup['id'],
  params: ContactGroupParams,
) {
  return this.api.put<ContactGroupParams, AxiosResponse<ContactGroup>>(
    `/contactgroups/${groupId}`,
    params,
  );
}

export function deleteContactGroup(this: RestApiService, groupId: ContactGroup['id']) {
  return this.api.delete<number>(`/contactgroups/${groupId}`);
}

export function addContactsToGroup(
  this: RestApiService,
  groupId: ContactGroup['id'],
  contactIds: Contact['id'][] = [],
  contactTagIds: ContactTag['id'][] = [],
  notContactTagIds: ContactTag['id'][] = [],
  queryMode: QueryMode,
) {
  return this.api.post<Contact['id'][], AxiosResponse<ContactGroup>>(
    `/contactgroups/${groupId}/contacts`,
    { contactIds, contactTagIds, notContactTagIds, queryMode },
  );
}

export function removeContactsFromGroup(
  this: RestApiService,
  groupId: ContactGroup['id'],
  contactIds: Contact['id'][],
) {
  return this.api.delete<Contact['id'][], AxiosResponse<ContactGroup>>(
    `/contactgroups/${groupId}/contacts`,
    {
      data: contactIds,
    },
  );
}

export function detachContactGroup(
  this: RestApiService,
  groupId: ContactGroup['id'],
  collaboratorId: User['id'],
) {
  return this.api.delete<null>(`/contactgroups/${groupId}/collaborators/${collaboratorId}`);
}
