import { createSlice } from '@reduxjs/toolkit';
import { createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import config from 'config';

interface ContactsState {
  onboarded: boolean;
}

const initialState: ContactsState = {
  onboarded: false,
};

const migrations = {
  '1': (state: ContactsState) => {
    return {
      onboarded: state.onboarded,
    };
  },
};

export const contactsPersistConfig = {
  key: `${config.LOCAL_STORAGE_ID}.contacts`,
  version: 1,
  storage,
  // @ts-ignore
  migrate: createMigrate(migrations, { debug: false }),
};

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    TOGGLE_CONTACTS_ONBOARDED: state => {
      return {
        ...state,
        onboarded: !state.onboarded,
      };
    },
  },
});

export const { actions: contactsActions } = contactsSlice;

export default contactsSlice.reducer;
