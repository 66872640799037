import React from 'react';

import { UserFeatureFlags } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

const componentPromise = import('./ContactTagsRoute');
const Component = React.lazy(() => componentPromise);

export default {
  component: Component,
  exact: true,
  path: [Routes.ContactTagsPublish],
  authenticated: true,
  userFeatureFlag: UserFeatureFlags.EDIT_CONTACT_MANAGEMENT,
};
