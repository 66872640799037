import { UserFeatureFlags } from 'types/user.types';

import { Routes } from 'pages/routes.constants';
import { Route as RouteProps } from 'pages/routes.types';

// Default routes for each user type
const SHARED_ALLOWED_ROUTES: string[] = [
  Routes.RedirectNotAuthorized,
  Routes.SessionHijacked,
  Routes.Share,
  Routes.SharedArticles,
  Routes.Root,
  Routes.Unsubscribe,
  Routes.Register,
];

export const Unauthorized = {
  isAuthorized: false,
  redirectTo: Routes.RedirectNotAuthorized,
};

const Authorized = {
  isAuthorized: true,
  redirectTo: '',
};

const featureFlagRoutesMapping: { [index: string]: Routes[] } = {
  [UserFeatureFlags.BOARDS]: [
    Routes.Boards,
    Routes.BoardsAllContent,
    Routes.BoardsAllContentArticle,
    Routes.BoardsBelga,
    Routes.BoardsDetail,
  ],
  [UserFeatureFlags.FEEDS]: [Routes.Feeds, Routes.FeedsDetail],
  [UserFeatureFlags.KIOSK_FRONTPAGE]: [Routes.KioskFrontPage, Routes.KioskBelgaImage],
  [UserFeatureFlags.KIOSK_NEWSLETTERS]: [
    Routes.KioskNewsletters,
    Routes.KioskNewslettersDetail,
    Routes.KioskNewslettersDetailSearch,
    Routes.RecurringNewsletterLatestEdition,
    Routes.KioskNewslettersDetailShared,
    Routes.KioskNewslettersDetailArticle,
    Routes.KioskNewslettersDetailLabelSummaryArticle,
  ],
  [UserFeatureFlags.KIOSK_NEWSROOMS]: [
    Routes.KioskPressReleases,
    Routes.KioskNewsrooms,
    Routes.KioskNewsroomsDetail,
    Routes.KioskPressReleaseDetail,
    Routes.SettingsNotifications,
  ],
  [UserFeatureFlags.TOPICS]: [
    Routes.KioskTopicsOverview,
    Routes.KioskTopicsDetail,
    Routes.KioskTopicsDetailArticle,
  ],
  [UserFeatureFlags.KIOSK_REPORTS]: [
    Routes.KioskReports,
    Routes.KioskReportsDetail,
    Routes.RecurringReportLatestEdition,
  ],
  [UserFeatureFlags.KIOSK_PUBLICATIONS]: [
    Routes.KioskPublications,
    Routes.KioskPublicationsDetail,
    Routes.KioskPublicationsSearch,
  ],
  [UserFeatureFlags.KIOSK_REALTIME]: [Routes.KioskRealtime],
  [UserFeatureFlags.KIOSK_NEWSBRANDS]: [Routes.KioskNewsbrands, Routes.KioskNewsbrandsDetail],
  [UserFeatureFlags.KIOSK_FEEDS]: [Routes.KioskFeedOverview, Routes.KioskFeedDetail],
  [UserFeatureFlags.KIOSK_RECOMMENDED]: [
    Routes.KioskRecommended,
    Routes.KioskRecommendedDetail,
    Routes.KioskRecommendedOldPressReviews,
  ],
  [UserFeatureFlags.KIOSK_MULTIMEDIA]: [],

  [UserFeatureFlags.KIOSK_GOPRESS_ARCHIVE]: [
    Routes.KioskGopressArchive,
    Routes.KioskGopressArchiveDetail,
  ],
  [UserFeatureFlags.KIOSK_AGENDA_PREVIEW]: [Routes.KioskAgenda],
  [UserFeatureFlags.SAVED_NEWS]: [Routes.SavedItems],
  [UserFeatureFlags.LABELS]: [Routes.Labels, Routes.LabelsDetail],
  [UserFeatureFlags.SEARCH]: [Routes.Search],
  [UserFeatureFlags.PRINT]: [Routes.Print],
  [UserFeatureFlags.ANALYSIS]: [Routes.Reports, Routes.ReportDetail],
  [UserFeatureFlags.ANALYSIS_PREVIEW]: [
    Routes.Analysis,
    Routes.ExploratoryAnalysis,
    Routes.SavedAnalyses,
    Routes.SavedAnalysisDetail,
  ],
  [UserFeatureFlags.NEWSROOMS]: [
    Routes.Newsrooms,
    Routes.NewsroomsInsights,
    Routes.NewsroomsInsightsPressReleases,
    Routes.NewsroomsInsightsPressReleaseDetail,
    Routes.NewsroomsDetail,
    Routes.NewsroomsPressReleases,
    Routes.NewsroomsPressReleasesDetail,
  ],
  [UserFeatureFlags.JOURNALIST_NEWSROOM]: [Routes.Journalist, Routes.JournalistDetail],
  [UserFeatureFlags.NEWSLETTERS]: [Routes.Newsletters, Routes.Newsletters, Routes.NewsletterDetail],
  [UserFeatureFlags.USER_SETTINGS]: [Routes.Settings, Routes.SettingsGeneral],
  [UserFeatureFlags.EDIT_BRANDS]: [Routes.SettingsBrands],
  [UserFeatureFlags.EDIT_TEMPLATES]: [
    Routes.SettingsTemplates,
    Routes.SettingsTemplatesDetail,
    Routes.SettingsRecurringTemplates,
    Routes.SettingsRecurringTemplatesDetail,
  ],
  [UserFeatureFlags.EDIT_CONTACT_MANAGEMENT]: [
    Routes.ContactsPublish,
    Routes.ContactGroupsPublish,
    Routes.ContactGroupsDetailPublish,
    Routes.ContactTagsPublish,
    Routes.ContactsShare,
    Routes.ContactGroupsShare,
    Routes.ContactGroupsDetailShare,
    Routes.ContactTagsShare,

    // TODO: Remove these routes after a while - Added on 11/24
    Routes.SettingsContactsInfo,
    Routes.SettingsContactGroupsInfo,
  ],
  [UserFeatureFlags.EDIT_USER_MANAGEMENT]: [
    Routes.SettingsUsers,
    Routes.SettingsUsersDetail,
    Routes.SettingsSubscriptions,
    Routes.SettingsNewsletterStatistics,
  ],
  [UserFeatureFlags.EDIT_KEYWORDS]: [Routes.SettingsKeywords],
  [UserFeatureFlags.PAY_PER_ARTICLE]: [Routes.SettingsPurchasedArticles],
  [UserFeatureFlags.ACTIVITY_REPORT]: [Routes.SettingsActivityReports],
  [UserFeatureFlags.NEWSLETTER_STATISTICS]: [Routes.SettingsNewsletterStatistics],
  [UserFeatureFlags.MY_DOCUMENTS]: [Routes.Documents],
};

const isRouteAllowed = (path: RouteProps['path'], allowedRoutes: string[]) => {
  const paths = Array.isArray(path) ? path : [path];
  return allowedRoutes.some(route => paths.includes(route));
};

const extendRoutesWithFeatureFlags = (featureFlags: UserFeatureFlags[]) =>
  featureFlags.reduce<Routes[]>((memo, flag) => {
    const routes = featureFlagRoutesMapping[flag];
    return routes ? [...memo, ...routes] : memo;
  }, []);

export const getRouteProtection = (path: RouteProps['path'], featureFlags: UserFeatureFlags[]) => {
  const extendedRoutes = extendRoutesWithFeatureFlags(featureFlags);
  const routes = [...SHARED_ALLOWED_ROUTES, ...extendedRoutes];
  return isRouteAllowed(path, routes) ? Authorized : Unauthorized;
};
