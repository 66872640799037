import { SxProps, Theme } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import Chip from '../Chip';

interface Props {
  sx?: SxProps<Theme>;
}

const NewChip = ({ sx }: Props) => {
  return (
    <Chip sx={sx}>
      <FormattedMessage id="common.new" />
    </Chip>
  );
};

export default NewChip;
