import { ApiResponse } from 'types/api.types';
import { Contact } from 'types/contact.types';
import { ContactTag, ContactTagParams } from 'types/contact-tag.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export function fetchContactTags(
  this: RestApiService,
  userId: User['id'],
  params?: ContactTagParams,
) {
  return this.api.get<ApiResponse<ContactTag[]>>(`/users/${userId}/contacttags`, { params });
}

export function createContactTag(
  this: RestApiService,
  userId: User['id'],
  tagName: ContactTag['name'],
) {
  return this.api.post<ContactTag>(`/users/${userId}/contacttags`, { name: tagName });
}

export function updateContactTag(this: RestApiService, userId: User['id'], tag: ContactTag) {
  return this.api.put<ContactTag>(`/users/${userId}/contacttags/${tag.id}`, { name: tag.name });
}

export function deleteContactTag(
  this: RestApiService,
  userId: User['id'],
  tagId: ContactTag['id'],
) {
  return this.api.delete<ContactTag>(`/users/${userId}/contacttags/${tagId}`);
}

export function clearContactTag(this: RestApiService, userId: User['id'], tagId: ContactTag['id']) {
  return this.api.delete<ContactTag>(`/users/${userId}/contacttags/${tagId}/contacts/clear`);
}

export function assignContactTags(
  this: RestApiService,
  userId: User['id'],
  tagId: ContactTag['id'],
  contactIds: Contact['id'][],
) {
  return this.api.post(`/users/${userId}/contacttags/${tagId}/contacts`, contactIds);
}
