import '../fonts.css';

import { Components, Theme } from '@mui/material';

export const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: `
  *,
  *:before,
  *:after {
    box-sizing: inherit;
    -webkit-overflow-scrolling: touch;
  }


  html,
  body,
  #root {
    height: 100%;
    font-family: "Proxima Nova", sans-serif;
  }

  body {
    margin: 0;
    overflow: hidden;
  }

  html {
    color: #222;
    font-weight: 100;
    font-size: 14px;
    font-family: "Proxima Nova", sans-serif;
    line-height: 1.5;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  a {
    color: #0074c2;
  }

  em {
    font-style: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: inherit;
  }

  button {
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    border-radius: 12px;
    box-shadow: inset 0 0 10px 10px transparent;
    border: solid 3px transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    box-shadow: inset 0 0 10px 10px #dbdbdb;
    border: solid 3px transparent;
  }

  /*
  * Disable webkit input type search styles
  */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  /*
  * Remove text-shadow in selection highlight:
  * https://twitter.com/miketaylr/status/12228805301
  *
  * These selection rule sets have to be separate.
  * Customize the background color to match your design.
  */

  ::-moz-selection {
    color: #3349e8;
    background: #ebecf5;
    text-shadow: none;
  }

  ::selection {
    color: #3349e8;
    background: #ebecf5;
    text-shadow: none;
  }

  /*
  * A better looking default horizontal rule
  */

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
  }

  /*
  * Remove the gap between audio, canvas, iframes,
  * images, videos and the bottom of their containers:
  * https://github.com/h5bp/html5-boilerplate/issues/440
  */

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  /*
  * Remove default fieldset styles.
  */

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  /*
  * Allow only vertical resizing of textareas.
  */

  textarea {
    resize: vertical;
  }

  /*
  * Browser upgrade prompt
  * ========================================================================== */

  :global(.browserupgrade) {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
  }

  /*
  * Print styles
  * Inlined to avoid the additional HTTP request:
  * http://www.phpied.com/delay-loading-your-print-css/
  * ========================================================================== */

  @media print {
    @page {
      size: auto;
      margin: 15mm 5mm;
    }

    body {
      overflow: scroll;
    }
    #root {
      height: auto;
    }

    div {
      overflow: visible !important;
      height: auto !important;
    }

    *,
    *::before,
    *::after {
      background: transparent !important;
      color: #000; /* Black prints faster: http://www.sanbeiji.com/archives/953 */
      box-shadow: none !important;
      text-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]::after {
      content: ' (' attr(href) ')';
    }

    abbr[title]::after {
      content: ' (' attr(title) ')';
    }

    /*
    * Don't show links that are fragment identifiers,
    * or use the javascript: pseudo protocol
    */

    a[href^='#']::after,
    a[href^='javascript:']::after {
      content: '';
    }

    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }

    /*
    * Printing Tables:
    * http://css-discuss.incutio.com/wiki/Printing_Tables
    */
    thead {
      display: table-header-group;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    img {
      max-width: 100% !important;
    }

    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }

    /*
    * Firefox does not support printing flex styles, this fixes the cutoff on the first page
    */
    @-moz-document url-prefix() {
      a {
        display: none !important;
      }
    }
  }

  /*
  * Remove default input type="number" styles.
  */

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  /* hide react-datepicker aria labels */
  .react-datepicker__aria-live {
    display: none;
  }

  .rdw-link-decorator-icon {
    left: 100%;
    padding-left: 5px;
  }
  `,
};
