import type { AxiosResponse } from 'axios';
import qs from 'qs';

import { Job } from 'store/job-queue/jobs.types';
import { ApiResponse } from 'types/api.types';
import {
  Contact,
  ContactSearchParams,
  CreateContactsParams,
  ExportContactsParams,
  UpdateContactParams,
} from 'types/contact.types';
import { User } from 'types/user.types';

import RestApiService from '.';

export function fetchContacts(
  this: RestApiService,
  userId: User['id'],
  params?: ContactSearchParams,
) {
  return this.api.get<ApiResponse<Contact[]>>(`/users/${userId}/contacts`, { params });
}

export function fetchContactsAsyncTask(
  this: RestApiService,
  userId: User['id'],
  jobIds: Job['requestId'][] = [],
) {
  return this.api.get<ApiResponse<Job[]>>(`/users/${userId}/contacts/asyncTask`, {
    params: { requestIds: jobIds },
    paramsSerializer: p => qs.stringify(p, { arrayFormat: 'comma' }),
  });
}

export function createContacts(
  this: RestApiService,
  userId: User['id'],
  params: CreateContactsParams[],
) {
  return this.api.post<CreateContactsParams[], AxiosResponse<Contact[]>>(
    `/users/${userId}/contacts`,
    params,
  );
}

export function createContactsAsyncTask(
  this: RestApiService,
  userId: User['id'],
  params: CreateContactsParams[],
) {
  return this.api.post<CreateContactsParams[], AxiosResponse<Job['requestId']>>(
    `/users/${userId}/contacts/asyncTask`,
    params,
  );
}

export function updateContact(
  this: RestApiService,
  userId: User['id'],
  contactId: Contact['id'],
  params: UpdateContactParams,
) {
  return this.api.put<UpdateContactParams, AxiosResponse<Contact>>(
    `/users/${userId}/contacts/${contactId}`,
    params,
  );
}

export function deleteContact(this: RestApiService, userId: User['id'], contactId: Contact['id']) {
  return this.api.delete<Contact>(`/users/${userId}/contacts/${contactId}`);
}

export function deleteContacts(
  this: RestApiService,
  userId: User['id'],
  contactIds: Contact['id'][],
) {
  return this.api.delete<Contact>(`/users/${userId}/contacts`, { data: contactIds });
}

export async function fetchExportContacts(
  this: RestApiService,
  userId: User['id'],
  params?: ExportContactsParams,
) {
  return this.api.get<Blob>(`/users/${userId}/contacts/export`, {
    headers: {
      Accept: 'text/csv',
      'Content-Type': 'text/csv',
    },
    data: null, // need to set data to explicitly because otherwise axios does not send the content-type header
    responseType: 'blob',
    params,
  });
}
