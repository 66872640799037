import React from 'react';

import { UserFeatureFlags } from 'types/user.types';

import { Routes } from 'pages/routes.constants';

const componentPromise = import('./ContactGroupsRoute');
const Component = React.lazy(() => componentPromise);

export default {
  component: Component,
  exact: true,
  path: [Routes.ContactGroupsPublish],
  authenticated: true,
  // TODO: Not sure if this is the correct feature flag
  userFeatureFlag: UserFeatureFlags.EDIT_CONTACT_MANAGEMENT,
};
